<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :clearable="clearable"
            :showAdd="showAdd"
            :dense="dense"
            :multiple="multiple"
            :selectAll="selectAll"
            :autoComplete="autoComplete"
            :disabled="disabled"
            :item-text="itemText"
        />
        <sys-register-block @onCompleted="newItem" :model="{}" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectBlock',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        showForm: false
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        this.getItems();
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        dense: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        campusId: {
            default: null
        },
        placeId: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: 'name'
        },
        
    },
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },

        campusId(){
            this.getItems();
        },

        placeId(){
            this.getItems();
        },
        
        model(val){
            this.selected = val;
        },
    },
    methods: {
        ...mapActions('block', ['ActionFindBlocks']),        
        
        async getItems() {

            this.loading = true;
            var payload = {
                column_order: 'name'
            };

            if(this.campusId && this.campusId.length !== 0){
                payload.filter_relations = [
                    {
                        name:   'ambiences',
                        key:    'campus_id', 
                        value:  this.campusId,
                    }    
                ];
            }

            if(this.placeId){
                payload.find_columns = {
                    place_id: this.placeId
                };
            }

            await this.ActionFindBlocks(payload)
                    .then((res) => {
                        this.items = res.data;
                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

    newItem(item){
            
            if(item){
                this.items.push(item);
                this.selected = item.id;
                this.showForm = false;
            }
        }
    },
    
}
</script>