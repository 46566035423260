import { render, staticRenderFns } from "./DynamicAttributionType.vue?vue&type=template&id=4606c02f&"
import script from "./DynamicAttributionType.vue?vue&type=script&lang=js&"
export * from "./DynamicAttributionType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports