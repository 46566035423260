<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-clipboard-list-outline" 
        v-bind:show.sync="dialog"
        maxWidth="60%"
    >
    
        <template v-slot:tools>
            <sys-activity-item-pop-over-time-table :model="config" />
        </template>
    
    <template v-slot:form>
            <v-tabs class="ma-0" v-model="tab">
                <v-tab key="main">Dados Principais</v-tab>
                <v-tab key="time_table">Horários</v-tab>
                <v-tab key="teachers" v-if="isMultiTeacher && !teacher">Professores</v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab">
                <v-tab-item key="main">
                    <v-container>
                        <v-row class="mt-1" v-if="acesso.instituicao && !acesso.instituicao.settings.allow_multi_teachers_activities">
                            <v-col cols="12" sm="11">
                                <sys-select-teacher
                                    :model.sync="model.teacher_id"
                                    :error="errors.teacher_id && errors.length > 0" 
                                    :data.sync="model.teacher"
                                    :selectedObject.sync="teacherSelected"
                                    :errors="errors.teacher_id"
                                    label="Professor"
                                    autoComplete
                                    autofocus
                                    :disabled="teacher != null"
                                    clearable
                                    dense
                                />

                            </v-col>
                            <v-col cols="12" sm="1">
                                <v-btn v-if="model && model.id" dense :disabled="model && model.teacher_id === teacher_id_original" :loading="loadingUpdateTecher" color="success" small icon @click.prevent.stop="updateTeacher()"><v-icon small dark>mdi-content-save-edit-outline</v-icon></v-btn>
                            </v-col>
                           
                        </v-row>
                        
                        <v-row  class="mt-1" >

                            <v-col cols="12" sm="2">
                                <sys-select-activity-item-entry-type
                                    :model.sync="model.activity_item_entry_type_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.activity_item_entry_type_id"
                                    label="Tipo Lançamento*"
                                    dense
                                    @change="changeEntryType($event)"
                                /> 
                            </v-col>

                            <v-col v-if="renderComponent" cols="12" sm="7">
                                
                                <sys-select-campus
                                    v-if="model.activity_item_entry_type_id == 1"
                                    :model.sync="model.campus_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.campus_id"
                                    label="Campus*"
                                    dense
                                    :disabled="restrict"
                                    auto-complete
                                    @change="changeCampus($event)"
                                />
                                <sys-select-departament
                                    v-else-if="model.activity_item_entry_type_id == 2"
                                    :model.sync="model.departament_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.departament_id"
                                    label="Departamento*"
                                    dense
                                    :disabled="restrict"
                                    auto-complete
                                    @change="changeDapartament($event)"
                                />

                            </v-col>

                            <v-col cols="12" sm="3">
                                <s-text-field
                                    v-if="renderComponentBusinessUnit"
                                    v-model="model.business_unit" label="Unidade Negócio" 
                                    dense 
                                    :disabled="restrict || disabledBusinessUnit" 
                                    :error-messages="errors.business_unit"
                                />
                            </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="9">
                                <sys-select-course
                                    :model.sync="model.courses"
                                    :error="errors.length > 0"  
                                    :errors="errors.course_id"
                                    :campus-id="model.campus_id"
                                    filter
                                    label="Cursos"
                                    multiple
                                    onlyActive
                                    dense
                                    @input="changeCourse($event)"
                                    :disabled="restrict || !model.campus_id || model.activity_item_entry_type_id == 2"
                                />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <sys-select-cost-center
                                    v-if="renderComponentCostCenter"
                                    :model.sync="model.cost_center_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.cost_center_id"
                                    label="Centro Custo"
                                    :disabled="restrict || disabledCostCenter"
                                    clearable
                                    auto-complete
                                    dense
                                    :filter-id="costCenterId"
                                /> 
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="9">
                                <sys-select-activity
                                    :model.sync="model.activity_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.activity_id"
                                    label="Atividade*"
                                    itemText="name_complete"
                                    :disabled="restrict"
                                    dense
                                    autoComplete
                                    @change="changeActivity($event)"
                                    :brand-id="selectedCampus ? selectedCampus.brand_id : null"
                                /> 
                            </v-col>
                            <v-col cols="12" sm="3">
                                <sys-select-payment-event
                                    :model.sync="model.payment_event_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.payment_event_id"
                                    label="Evento Pgto"
                                    item-text="initials"
                                    clearable
                                    auto-complete
                                    dense
                                    :disabled="disabledPaymentEvent"
                                /> 
                            </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="2">
                                <sys-select-payment-periodicity-type
                                    :model.sync="model.payment_periodicity_type_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.payment_periodicity_type_id"
                                    label="Periodicidade*"
                                    dense
                                    :disabled="restrict || (selectedActivity != null && selectedActivity.paymentEvent != null && selectedActivity.paymentEvent.payment_periodicity_type_id != null)"
                                /> 
                            </v-col>

                            <v-col cols="12" sm="3">
                                <sys-select-value-type
                                    :model.sync="model.value_type_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.value_type_id"
                                    label="Tipo de Valor*"
                                    dense
                                    @change="forceRerenderValue()"
                                    :disabled="restrict || (selectedActivity && selectedActivity.paymentEvent && selectedActivity.paymentEvent.value_type_id)"
                                /> 
                            </v-col>

                            <v-col cols="12" sm="3">
                                <s-text-field
                                    v-if="renderComponentValue"
                                    v-model="model.value"
                                    dense 
                                    :error-messages="errors.value" 
                                    :label="labelValue"
                                    :disabled="restrict"
                                    text-helper="Utilizar '.' (ponto) como separador decimal"
                                />
                            </v-col>

                            <v-col cols="12" sm="2">
                                <emc-calendar-date-picker2 
                                    :model.sync="model.date_start" 
                                    label="Início*" 
                                    :errorMessages="errors.date_start"
                                    :show="dialog"
                                    :disabled="restrict"
                                    :min="acesso.periodo.start"
                                    :max="acesso.periodo.end"
                                    dense
                                />
                            </v-col>

                            <v-col cols="12" sm="2">
                                <emc-calendar-date-picker2 
                                    :model.sync="model.date_end" 
                                    label="Fim*"
                                    :errorMessages="errors.date_end" 
                                    :show="dialog"
                                    :disabled="restrict"
                                    :min="acesso.periodo.start"
                                    :max="acesso.periodo.end"
                                    dense
                                />
                            </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    :error="!!errors.note" 
                                    :error-messages="errors.note" 
                                    v-model="model.note" 
                                    label="Observação"
                                    outlined
                                    clearable
                                    rows="3"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <s-switch text-helper="Atividade não remunerada para docentes com cargo configurado para Regime TI/TP" inset v-model="model.not_pay_work_regime" label="Não Pagar TI/TP"/>
                            </v-col>
                            <!-- <v-col cols="12" sm="4">
                                <sys-select-course-level
                                    :model.sync="model.course_level_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.course_level_id"
                                    label="Nível Curso"
                                    clearable
                                    auto-complete
                                    dense
                                /> 
                            </v-col> -->
                            <v-col cols="12" sm="2">
                                <s-text-field dense :disabled="restrict" :error-messages="errors.students" v-model="model.students" label="Alunos" />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-text-field dense :disabled="restrict" code :error-messages="errors.code" v-model="model.code" label="Código" />
                            </v-col>
                        </v-row>
                        
                </v-container>
                </v-tab-item>
                <v-tab-item key="time_table">
                    <sys-activity-item-register-time-table :model="model" :errors="errors"/>
                </v-tab-item>
                
                <!-- Professores das Atividades -->
                <v-tab-item  v-if="acesso.instituicao && acesso.instituicao.settings.allow_multi_teachers_activities && !teacher" key="teachers">
                    <sys-activity-item-register-teachers :work-time="acesso.periodo" :model="model" :errors="errors"/>
                </v-tab-item>
                
            </v-tabs-items>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterActivityItem",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        tab: null,
        renderComponent: true,
        renderComponentBusinessUnit: true,
        renderComponentValue: true,
        renderComponentCostCenter: true,
        loading: false,
        loadingUpdateTecher: false,
        teacher_id_original: null,
        teacherSelected: { campuses: [] },
        disabledCostCenter: false,
        disabledPaymentEvent: false,
        disabledBusinessUnit: false,
        costCenterId: null,
        selectedActivity: null,
        selectedCampus: null,
        selectedDepartament: null,
        courseInfos: null,
        config: {
            allow_shock_time_table: false,
            allow_journey: false,
            allow_shock_ambience: false,
            allow_intra_day: false,
            allow_max_hours_day: false,
            allow_work_load: false,
            allow_work_load_activity: false,
        }
    }),
    created(){
       this.loadCourseInfos();
    },

    computed: {
      ...mapState('auth', ['acesso', 'user']),

      isMultiTeacher(){
        return this.acesso.instituicao && this.acesso.instituicao.settings.allow_multi_teachers_activities;
      },

      labelValue(){

        switch (this.model.value_type_id) {
            case 1: return 'Carga Horária*';
            case 2: return 'Valor (R$)*';
            case 3: return 'Quantidade*';
            default: return 'Valor';
        }

      },

    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        teacherSelected(val){
            
            if(val && val.campuses && val.campuses.length == 1){
                this.model.campus_id = val.campuses[0].id;
            }
            
        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.tab = null;
                this.config.allow_shock_time_table = 0;
                this.config.allow_journey = 0;
                this.config.allow_shock_ambience = 0;
                this.config.allow_intra_day = 0;
                this.config.allow_max_hours_day = 0;
                this.config.allow_work_load = 0;
                this.config.allow_work_load_activity = 0;
                this.teacher_id_original = null;
                this.selectedActivity     = null;
                this.disabledCostCenter   = false;
                this.disabledPaymentEvent = false;
                this.disabledBusinessUnit = false;
                this.selectedCampus = null;
                this.selectedDepartament = null;
                
                if(this.model.id){
                
                    if(this.model.teachers.length > 0){
                        let teacher = this.model.teachers[0].teacher;
                        this.model.teacher_id = teacher.id;
                        this.model.teacher = teacher;
                    }

                    this.teacher_id_original = this.model ? this.model.teacher_id : null;

                    this.model.value = parseFloat(this.model.value);

                    let entry = this.model.activity_item_entry_type_id;

                    if(entry == 1 && this.model.campus_id){

                        let businessUnit = this.getBusinessUnitIinfo();


                        if(!businessUnit){
                            businessUnit = this.model.campus.business_unit;
                        }
                        
                        this.disabledBusinessUnit = this.model.business_unit && businessUnit && this.model.business_unit == businessUnit;

                        if(this.model.cost_center_id && this.model.courses && this.model.courses.length > 0){
                            this.model.courses.forEach(c => {
                                if(c.cost_center_id && c.cost_center_id == this.model.cost_center_id){
                                    this.disabledCostCenter = true;
                                }
                            });
                        }

                    }else if(entry == 2 && this.model.departament_id){

                        this.disabledBusinessUnit = this.model.business_unit && this.model.departament.business_unit && this.model.business_unit == this.model.departament.business_unit;

                        if(this.model.cost_center_id && this.model.departament.cost_center_id && this.model.departament.cost_center_id == this.model.cost_center_id){
                            this.disabledCostCenter = true;
                        }
                    }
                    
                    if(this.model.payment_event_id && this.model.activity.payment_event_id && this.model.activity.payment_event_id == this.model.payment_event_id){
                        this.disabledPaymentEvent = true;
                    }
                    
                    
                }else{
                    
                    this.model.value_type_id                = 1;
                    this.model.payment_periodicity_type_id  = 1;
                    this.model.activity_item_status_id      = 1;
                    this.model.timeTables                   = [];
                    this.model.teachers                     = [];
                    this.model.by_date                      = 0;
                    this.model.activity_item_entry_type_id  = 1;
                    
                    this.model.date_start = this.acesso.periodo.start;
                    this.model.date_end = this.acesso.periodo.end;

                    if(this.grid){
                        
                        let group               = this.grid.group;
                        this.model.campus_id    = group.campus_id;
                        this.model.value        = this.grid.work_load_pay;
                        this.model.courses      = [{id: group.course_id}];
                        this.model.teacher      = this.grid.teacher;
                        this.model.teacher_id   = this.grid.teacher.id;

                        this.changeCampus(group.campus);
                        this.changeCourse([group.course]);
                        
                        if(this.grid.teacher){
                            this.model.teachers = [
                                {
                                    id:         0,
                                    teacher_id: this.grid.teacher.id,
                                    start:      this.model.date_start,
                                    end:        this.model.date_end,
                                    value:      this.model.value,
                                }
                            ];
                        }
                        
                    }
                }

                if(this.teacher){
                    this.model.teacher_id = this.teacher.id;
                    this.model.teacher = this.teacher;
                }

            }
            
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-clipboard-list-outline"
        },
        title: {
            type: String,
            default: "Atividade"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: null
        },
        restrict: {
            type: Boolean,
            default: false
        },
        grid: {

        }
    },
    methods:{
        ...mapActions('activityItem', ['ActionUpdateActivityItem', 'ActionCreateActivityItem', 'ActionUpdateActivityItemTeacher']),
        ...mapActions('user', ['ActionIndexUsers']),
        ...mapActions('courseInfo', ['ActionFindCourseInfos']),

        loadCourseInfos(){

            this.ActionFindCourseInfos()
                .then((res) => {
                    this.courseInfos = res.data;
                });

        },
        
        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {work_time_id: this.acesso.periodo.id, config: this.config});

            if(this.teacher){
                this.model.teachers = [
                    {
                        id:         0,
                        teacher_id: this.teacher.id,
                        start:      this.model.date_start,
                        end:        this.model.date_end,
                        value:      this.model.value,
                    }
                ];
            }

            this.model.teachers.forEach(item => {
                
                if(this.isMultiTeacher){

                    if(item.start < this.model.date_start){
                        item.start = this.model.date_start;
                    }

                    if(item.end > this.model.date_end){
                        item.end = this.model.date_end;
                    }

                }else{

                    item.start  = this.model.date_start;
                    item.end    = this.model.date_end;
                    item.value  = this.model.value;

                }

            });

            if(edit) {
                this.ActionUpdateActivityItem(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateActivityItem(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        changeActivity(item){
           
            this.selectedActivity = item;
            this.model.payment_periodicity_type_id = 1;
            this.model.value_type_id = 1;
            
            if(this.selectedActivity && this.selectedActivity.paymentEvent){
                
                let event = this.selectedActivity.paymentEvent;
                
                if(event.payment_periodicity_type_id){
                    this.model.payment_periodicity_type_id = event.payment_periodicity_type_id;
                }

                if(event.value_type_id){
                    this.model.value_type_id = event.value_type_id;
                }

                this.model.payment_event_id = event.id;
                this.disabledPaymentEvent = true;
                
            }else{
                this.model.payment_event_id = null;
                this.disabledPaymentEvent = false;
            }
        },

        changeEntryType(item){
            
            this.model.campus_id        = null;
            this.model.departament_id   = null;
            this.model.business_unit    = null;
            this.model.cost_center_id   = null;
            this.model.payment_event_id = null;
            this.selectedCampus         = null;
            this.selectedDepartament    = null;
            
            this.forceRerender();


        },

        changeCampus(item){

            this.selectedCampus         = item;
            this.model.business_unit    = item.business_unit;
            this.disabledBusinessUnit   = this.model.business_unit != null;
            this.forceRerenderBusinessUnit();


        },

        changeDapartament(item){

            this.selectedDepartament    = item;
            this.model.business_unit    = item.business_unit;
            this.model.cost_center_id   = item.cost_center_id;
            this.disabledBusinessUnit   = this.model.business_unit != null;
            this.disabledCostCenter     = this.model.cost_center_id != null;
            this.forceRerenderBusinessUnit();
            this.forceRerenderCostCenter();

        },

        changeCourse(item){

            let ids = [];

            item.forEach(c => {
                if(c.cost_center_id){
                    ids.push(c.cost_center_id);
                }
            });

            if(ids.length > 0){
                this.costCenterId = ids.map(i => i).join(',');

                if(ids.length == 1){
                    this.disabledCostCenter = true;
                    this.model.cost_center_id = ids[0];
                }else{
                    this.disabledCostCenter = false;
                }

                
            }else{
                this.costCenterId = null;
                this.disabledCostCenter = false;
                this.model.cost_center_id = null;
            }

            // let businessUnit = this.getBusinessUnitIinfo(null, item);

            // if(businessUnit){
            //     this.model.business_unit    = businessUnit;
            //     this.disabledBusinessUnit   = true;
            // }
            
        },

        getBusinessUnitIinfo(campusId, courses){

            
            if(!campusId){
                campusId = this.model.campus_id;
            }

            if(!courses){
                courses = this.model.courses;
            }
            
            
            if(campusId && this.courseInfos && this.courseInfos.length > 0 && courses && courses.length > 0){
                
                let ids = courses.map(i => i.id);

                let find = this.courseInfos.find(i => (i.campus_id == campusId && ids.includes(i.course_id)));

                if(find){
                    return find.business_unit;
                }

            }

            return null;

        },

        updateTeacher()
        {
            this.error = false;
            this.message = '';
            this.loadingUpdateTecher = true;

            var payload = {
                id:         this.model.id,
                teacher_id: this.model.teacher_id,
            }

           
            this.ActionUpdateActivityItemTeacher(payload)
            .then((res) => {
                this.message = res.message;
                this.$emit('onCompleted');
            })
            .catch((error) =>{
                this.error      = true;
                this.errors     = error.errors;
                this.message    = error.message;
            })
            .finally(() => {
                    this.loadingUpdateTecher = false;
            });
            
        },

        forceRerender () {
      
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });

        },

        forceRerenderBusinessUnit () {
      
            this.renderComponentBusinessUnit = false;

            this.$nextTick(() => {
                this.renderComponentBusinessUnit = true;
            });

        },

        forceRerenderCostCenter () {
      
            this.renderComponentCostCenter = false;

            this.$nextTick(() => {
                this.renderComponentCostCenter = true;
            });

        },

        forceRerenderValue () {
      
            this.renderComponentValue = false;

            this.$nextTick(() => {
                this.renderComponentValue = true;
            });

        },
        
    }
}
</script>