export default [
    {
        path: '/integration-itens',
        name: 'integration-itens',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Items de Integração',
            icon: 'mdi-cog-transfer-outline',
        }
    }
]