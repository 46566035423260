<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-format-list-text" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-text-field dense autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-text-field dense code :error-messages="errors.code" v-model="model.code" label="Código"/>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.description" 
                                :error-messages="errors.description" 
                                v-model="model.description" 
                                label="Descrição"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                clearable
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <sys-select-payment-event
                                :model.sync="model.payment_event_id"
                                :error="errors.length > 0"  
                                :errors="errors.payment_event_id"
                                label="Evento de Pagamento"
                                item-text="name_complete"
                                showAdd
                                clearable
                                auto-complete
                            /> 
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-teacher-contract-type
                                :model.sync="model.teacher_contract_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.teacher_contract_type_id"
                                label="Tipo Vínculo"
                            /> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-activity-category
                                :model.sync="model.activity_category_id"
                                :error="errors.length > 0"  
                                :errors="errors.activity_category_id"
                                label="Categoria"
                                showAdd
                                clearable
                                auto-complete
                                dense
                            /> 
                        </v-col>
                        <v-col cols="12" sm="6">
                            <sys-select-brand
                                :model.sync="model.brands"
                                :error="errors.length > 0"  
                                :errors="errors.brands"
                                label="Marca"
                                multiple
                                dense
                            /> 
                        </v-col>
                       
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <sys-select-course-level
                                :model.sync="model.course_level_id"
                                :error="errors.length > 0"  
                                :errors="errors.course_level_id"
                                label="Nível de Curso"
                                clearable
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch text-helper="Considerar atividade para compor a carga horária semestral para controle de redução." inset v-model="model.is_work_load" label="Compõe CH"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch text-helper="Atividade com horário obrigatório definido" inset v-model="model.has_time_table" label="Com Horário"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-switch inset v-model="model.digital" label="Digital"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-switch inset v-model="model.active" label="Ativo"></v-switch>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterActivity",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.active = 1;
                    this.model.teacher_contract_type_id = 1;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-format-list-text"
        },
        title: {
            type: String,
            default: "Atividade"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('activity', ['ActionUpdateActivity', 'ActionCreateActivity']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id})

            if(edit) {
                this.ActionUpdateActivity(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateActivity(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>