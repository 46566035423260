<template>
    <div class="text-center ml-2">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :open-on-hover="openOnHover"
        :close-delay="delay"
        offset-y
        bottom
        :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '90%'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            color="grey"
            :small="!small"
          > 
            <v-icon v-if="openOnHover" v-bind="attrs" :color="color" v-on="on">{{ icon }}</v-icon>  
            <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" :color="color" v-on="on">{{ icon }}</v-icon>  
                </template>
                <span>{{ title }}</span>
            </v-tooltip>
            
          </v-btn>
        </template>

      <v-card>
        <v-card-title class="ma-0 pa-0">
            <v-app-bar dense class="grey lighten-5 mb-4 elevation-1'">
                <v-toolbar-title class="subtitle-1">
                  {{ title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small class="mr-1" @click.native="menu = false" v-bind="attrs" v-on="on">
                          <v-icon color="red lighten-2">mdi-close</v-icon>
                      </v-btn>
                  </template>
                  <span>Fechar</span>
                </v-tooltip>
            </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-list dense>
              
              <emc-util-scroll maxHeight="600px">

                <span v-if="imports.length == 1 && imports[0] == 62">
                  <v-list-item>
                      <v-list-item-title>
                            <emc-excel-import-icon layout="Oferta" :delay.sync="delay" :menu.sync="menu" class="ma-0 pa-0" small param="offer_items_update" tool-tip="Importar" icon="mdi-upload" @onCompleted="importCompleted" />
                            Oferta
                      </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                      <v-list-item-title>
                            <emc-excel-import-icon layout="Tratativas Base" :delay.sync="delay" :menu.sync="menu" class="ma-0 pa-0" small param="offer_items_update" tool-tip="Importar" icon="mdi-upload" @onCompleted="importCompleted" />
                            Tratativas Base
                      </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                      <v-list-item-title>
                            <emc-excel-import-icon layout="Remanejamento" :delay.sync="delay" :menu.sync="menu" class="ma-0 pa-0" small param="offer_items_update" tool-tip="Importar" icon="mdi-upload" @onCompleted="importCompleted" />
                            Remanejamento
                      </v-list-item-title>
                  </v-list-item>
                </span>
                <span v-else>
                  <v-list-item v-for="(item, index) in items" :key="index">
                      <v-list-item-title>
                            <emc-excel-import-icon :showClearItems="[3].includes(item.id)" :delay.sync="delay" :menu.sync="menu" class="ma-0 pa-0" small hide-dialog :param="item.param" tool-tip="Importar" icon="mdi-upload" @onCompleted="importCompleted" />
                            {{ item.name }}
                      </v-list-item-title>
                    </v-list-item>
                </span>

                <!-- <v-list-item v-if="$slots.custom || !!$scopedSlots.custom">
                  <v-list-item-content>
                    <slot name="custom" />
                  </v-list-item-content>
                </v-list-item> -->

              </emc-util-scroll>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "SysPopOverReportBase",
    data: () => ({
      items: [],
      menu: false,
      delay: 200
    }),
    created() {
      this.getData();
    },
    props: {
        title: {
            type: String,
            default: 'Relatórios'
        },
        icon: {
          type: String,
          default: 'mdi-download-multiple'
        },
        disabled: {
          type: Boolean,
          default: false,
        },
        imports: {
          type: Array,
        },
        small: {
          type: Boolean,
          default: true,
        },
        color: {
            type: String,
            default: 'primary'
        },
        openOnHover: {
          type: Boolean,
          default: false,
        }
    },
    computed: {
      ...mapState('auth', ['user']),
    },
    methods: {
      ...mapActions('user', ['ActionIndexImports']),

      getData(){
        
        this.items = [];
        
        let payload = {
          find_columns: {
            active: 1,
          }
        };

        if(this.imports){
          payload.find_columns.id = this.imports.join(',');
        }

        this.ActionIndexImports(payload)
            .then((res) => {
                this.items = res.data;
            })
            .finally(() => {
               
            })
      },

      importCompleted(error, message){
        this.menu = false;
        this.$emit('onCompleted', error, message);
      },

    },
}
</script>